import {useCookies} from "vue3-cookies";
import {getHost} from "@/hooks/fnHelper";

export default function deleteNonValidCookie() {
    const {cookies} = useCookies();
    if (location.pathname !== '/') {
        // console.log('deleteNonValidCookie location.pathname', location.pathname)
        const host = getHost();
        cookies.remove('server_sid', location.pathname.replace(/\/$/, ''), host);
        cookies.remove('server_sid', location.pathname.replace(/\/$/, '') + '/', host);
        cookies.remove('server_sid', location.pathname.replace(/\/$/, ''), 'market.polyanaski.ru');
        cookies.remove('server_sid', location.pathname.replace(/\/$/, '') + '/', 'market.polyanaski.ru');

        deleteCookieWithPath('server_sid', location.pathname.replace(/\/$/, ''));
        deleteCookieWithPath('server_sid', location.pathname.replace(/\/$/, '') + '/');
    }
}

function deleteCookieWithPath(name, path) {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}