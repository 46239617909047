import {svodApi} from "@/api/svodApi";

export function updateUlBalance(user) {
    if (user.isul) {
        svodApi.get('/userbill').then(({data}) => {
            user.bill = data.bill;
            user.overdraft = data.overdraft;
            user.balance = data.bill + data.overdraft;
        });
    }
}