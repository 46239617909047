<script>
import {skiPassApiBaseURL} from "@/api/skiPassApi";

export default {
    name: "OverdraftQrCodePopup",
    props: {
        code: String,
        amount: Number,
        successFn: {
            type: Function || null,
            default: null
        },
        failFn: {
            type: Function || null,
            default: null
        }
    },
    data() {
        return {
            paymentFail: false,
            successStatuses: [4, 5],
            failStatuses: [-20, -5, -3, -2, -1, 6],
            skiPassApiBaseURL: skiPassApiBaseURL
        }
    },

    mounted() {
        if(typeof this.successFn === "function") {
            this.successFn();
        }
    }
}
</script>

<template>
    <div class="popup" data-id="sbp-qr-code">
        <div class="popup-container sbp-qr-code">
            <div class="content">
                <div class="text">
                    <div class="head">
                        <span>QR-код заказа</span>
                    </div>
                    <img :src="skiPassApiBaseURL + '/drawcode/?type=qr&code=' + code" alt="">
<!--                    <div v-if="amount" class="amount mt20">
                        Оплачено: <b>{{ amount.toLocaleString('ru') }}</b> ₽
                    </div>-->
                </div>
                <div class="buttons">
                    <button class="btn btn-dark" @click="$emit('close')">Закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
