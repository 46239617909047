<script>
import {maska} from 'maska';
import {md5} from '@/hooks/md5Helper';
import {addURLParam, yaMetrika} from "@/hooks/fnHelper";
import {svodApi} from "@/api/svodApi";
import phoneImg from '@/assets/images/img-phone-summer.png';

export default {
    directives: {maska},
    props: {
        show: {
            type: Boolean,
            default: false
        },
        payload: {
            type: Object || null,
            default: null
        }
    },
    data() {
        return {
            images: {
                phoneImg: phoneImg,
            },
            needCode: true,
            form: {
                phone: '+7',
                smscode: '',
                apsid: '',
            },
            agree: false,
            showCallForm: false,
            repeatCodeTimer: 0,
            repeatCodeTimerInt: null,
            repeatCodeCounter: 0,
            loading: false,
            gidLoading: false,
            userRegistered: false,
            at: 1,
            repeatCodeCounterLimit: 2000,
        }
    },

    mounted() {
        this.$nextTick(() => {

            setTimeout(() => {
                if (this.$refs.phone) {
                    this.$refs.phone.focus();
                }
            }, 100);
        });
        // console.log('AuthPopup mounted');
    },

    watch: {
        repeatCodeCounter: function (val) {
            if (val >= this.repeatCodeCounterLimit) {
                this.at = 0;
            } else {
                this.at = 1;
            }
        },
        show: function (val) {
            if (val && !this.available) {
                this.$toast.error('Авторизация временно не доступна!');
                this.close();
            }
        }
    },

    computed: {
        mask() {
            let phone = this.form.phone.replace(/[^0-9]/g, '');
            return phone.length && phone[0] === '3' ? '+### ## ### ## ##' : '+# ### ### ## ##'
        },

        digits() {
            return Array(this.repeatCodeCounter <= this.repeatCodeCounterLimit ? 3 : 6).fill('');
        },
        available() {
            const loginRoute = this.$serverRouters.find(item => item.tagRoute === 'login');
            if (!loginRoute || !loginRoute.enabled || (loginRoute.isDebug && !this.$root.testMode)) {
                return false;
            }
            return true;
        }
    },

    methods: {
        onBtnClose() {
            if (this.payload?.onCancel instanceof Function) {
                this.payload.onCancel();
            }
            this.close();
        },
        close() {
            if (this.$route.meta.authOnly && !this.$root.isAuth) {
                this.$router.push({name: 'home'});
            }

            this.$emit('close');
        },
        changePhone() {
            this.needCode = true;
            this.repeatCodeCounter = 0;
        },
        getCode() {
            /*this.needCode = false;
            setTimeout(() => {
                this.$refs.smscode[0].focus();
            });
            return;
             console.log('this.$root.host', this.$root.host);*/

            /*this.$cookies.set('server_sid', '9E82C513F9F3847CA7A3EAD548DBE42F');
            this.$cookies.set('server_userid', 3574);

            this.$root.user = {
                "id": 3574,
                "email": "x_drw@bk.ru",
                "email2": "",
                "acctype": "Browser+Mobile",
                "fname": "Дмитрий",
                "mname": "Сергеевич",
                "sname": "Батминов",
                "phone": "79284525721",
                "gender": 1,
                "id_1chotel": "V00000059355",
                "city": "Краснодарский край, г Сочи",
                "avatar_file_id": 0,
                "user_posted": 1,
                "datereg": 1544020620,
                "birthday": 546652800,
                "date_confirm_email": 1710435433,
                "date_confirm_email2": 0,
                "date_upload_profbilet": 0,
                "profkomphone": "",
                "sd": 1711042144
            };
            this.$root.isAuth = true;
            this.$emit('close');
            console.log('this.payload.onAuth', this.payload?.onAuth, this.payload?.onAuth instanceof Function);
            if (this.payload?.onAuth instanceof Function) {
                this.payload.onAuth();
            }

            return;*/

            //console.log(JSON.stringify({"cmd": "sendsms", "cellnum": this.form.phone}));

            yaMetrika('reachGoal', 'market_auth_code');

            if (!this.loading && this.repeatCodeTimer <= 0) {
                let phone = this.form.phone.replace(/\D/g, '');

                let phoneLength = 11;
                if (parseInt(phone[0]) === 3 && parseInt(phone[1]) === 7 && parseInt(phone[2]) === 5) {
                    phoneLength = 12;
                }

                if (phone.length !== phoneLength) {
                    this.$toast.warning('Неверный номер телефона.');
                    setTimeout(() => {
                        this.$refs.phone.focus();
                    });
                    return;
                }

                let time = Date.now();
                this.loading = true;

                const params = new URLSearchParams()
                params.append('phone', phone);
                params.append('at', this.at);
                params.append('hash', md5(phone + '' + time));
                params.append('time', "" + time);
                params.append('deviceid', "1e3d40388488eeb14b9702ede52cb33d");
                params.append('sid', this.$cookies.get('server_sid'));

                this.axios.post('https://get.polyanaski.ru/getall.dll/postAuthPhoneNew', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(({data}) => {
                    if (data.error === 0) {
                        this.needCode = false;
                        this.userRegistered = !!data.user_registered;
                        this.form.apsid = data.sid;

                        this.repeatCodeTimer = 90;
                        28
                        setTimeout(() => {
                            this.$refs.smscode[0].focus();
                        }, 50);

                        this.repeatCodeTimerInt = setInterval(() => {
                            this.repeatCodeTimer--;
                            if (this.repeatCodeTimer <= 0) {
                                this.repeatCodeTimer = 0;
                                clearInterval(this.repeatCodeTimerInt);
                            }
                        }, 1000);
                    } else {
                        let serverError;
                        switch (data.error) {
                            case 1:
                                if (data.err_text.includes('Delay')) {
                                    serverError = 'Вход для указанного номера телефона временно недоступен. Пожалуйста, попробуйте позднее.';
                                } else if (data.err_text.includes('Not valid number')) {
                                    serverError = 'Номер телефона указан некорректно. Пожалуйста, проверьте введенный номер.';
                                } else if (data.err_text.includes('Not Russian or Belarus')) {
                                    serverError = 'Указанный номер телефона не может быть использован для входа.';
                                } else {
                                    serverError = 'Ошибка отправки кода на данный номер';
                                }
                                break;
                            case 2:
                                serverError = 'Ошибка на стороне БД';
                                break;
                            default:
                                serverError = 'Неизвестная ошибка сервера!';
                                break;
                        }
                        this.$toast.error(serverError);
                    }
                }).catch((error) => {
                    this.$toast.error(error.message);
                    this.$toast.error(error.request.response);
                }).finally(() => {
                    this.loading = false;
                    this.repeatCodeCounter++;
                });
            }
        },
        auth() {
            yaMetrika('reachGoal', 'market_auth_auth');

            if (!this.loading) {
                if (!this.userRegistered && !this.agree) {
                    this.$toast.warning('Необходимо согласиться с условиями обработки персональных данных.');
                    return;
                }

                this.loading = true;
                const params = new URLSearchParams()
                // params.append('sid', this.$cookies.get('server_sid'));
                params.append('sid', this.form.apsid);
                params.append('code', this.form.smscode);
                params.append('deviceid', "1e3d40388488eeb14b9702ede52cb33d");

                this.axios.post('https://get.polyanaski.ru/getall.dll/postauthcode', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(({data}) => {
                    if (data.error === 0) {
                        this.$cookies.remove('server_sid', null, this.$root.host);
                        this.$cookies.remove('server_userid', null, this.$root.host);
                        this.$cookies.set('server_sid', data.sid, null, null, this.$root.host);
                        this.$cookies.set('server_userid', data.userid, null, null, this.$root.host);
                        svodApi.defaults.headers.mrktsid = data.sid;
                        if (data.auth) {
                            this.axios.get('https://get.polyanaski.ru/getall.dll/userdata?sid=' + data.sid).then(({data}) => {
                                console.log('userdata data', data);
                                this.$root.isAuth = true;
                                this.$root.user = data;

                                this.form = {
                                    phone: '',
                                    smscode: '',
                                    apsid: '',
                                };
                                this.needCode = true;
                                this.repeatCodeTimer = 0;
                                clearInterval(this.repeatCodeTimerInt);
                                this.repeatCodeTimerInt = null;

                                setTimeout(() => {
                                    if (this.payload?.onAuth instanceof Function) {
                                        this.payload.onAuth();
                                    }
                                    this.close();
                                    // this.$router.go();
                                });
                            }).finally(() => {
                                this.loading = false;
                            });
                            svodApi.get('/lk?scope=lk+systems+auth&mrktsid=' + data.sid);
                        }
                        this.loading = false;
                    } else {
                        let serverError;
                        switch (data.error) {
                            case 1:
                                serverError = 'Неверный идентификатор сессии';
                                break;
                            case 2:
                                serverError = 'deviceid не соответствует сессии';
                                break;
                            case 3:
                                serverError = 'Неверный код';
                                break;
                            case 4:
                                serverError = 'Ошибка на стороне БД';
                                break;
                            case 5:
                                serverError = 'Нет пользователя, соответствующего сессии';
                                break;
                            default:
                                serverError = 'Неизвестная ошибка сервера!';
                                break;
                        }
                        this.$toast.error(serverError);
                        this.loading = false;
                    }
                }).catch(() => {
                    this.loading = false;
                });
            }
        },

        onFocus() {
            this.$nextTick(() => {
                this.$refs.phone.selectionStart = this.$refs.phone.value.length;
            });
        },

        moveToNextInput(index, val) {
            // console.log('moveToNextInput', val, val[0], index);
            // 345345
            val = val.replace(/\D/g, '');
            if (!val.length) {
                this.digits[index] = '';
                return;
            }

            let nextIndex = index + 1;
            this.digits[index] = val[0];
            if (nextIndex < this.digits.length) {
                this.$refs.smscode[nextIndex].focus();

                if (val.length > 1) {
                    this.moveToNextInput(nextIndex, val.slice(1), true);
                    return;
                }
            }

            if (this.digits.join('').length === (this.at ? 3 : 6)) {
                this.form.smscode = this.digits.join('');
                this.auth();
            }
        },

        moveToPrevInput(index) {
            const prevIndex = index - 1;
            if (this.digits[index]) {
                this.digits[index] = '';
            } else {
                this.digits[prevIndex] = '';
            }

            if (index > 0) {
                this.$refs.smscode[prevIndex].focus();
            }
        },

        async gidAuth() {
            if (!this.gidLoading) {
                this.gidLoading = true;

                let mrktsid = this.$cookies.get('server_sid') ?? '';

                this.$cookies.set("gazprom_sid", mrktsid, null, null, this.host);

                if (this.$route.name === 'booking') {
                    addURLParam(this.$router, 'send_data', 'Y');
                }

                svodApi.get('/auth/login?ssoname=SSO_gid&urlcallback=' + encodeURIComponent(location.href) + '&mrktsid=' + mrktsid).then(({data}) => {
                    location.href = data;
                }).catch(() => {
                    this.gidLoading = false;
                });
            }
        }
    }
}
</script>

<template>
    <div
        class="popup popup-auth"
        data-id="auth"
    >
        <div class="popup-container auth">
            <div
                class="btn-close"
                @click="onBtnClose"
            >
                <svg class="icon icon-close">
                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-close"></use>
                </svg>
            </div>
            <div id="auth">
                <div class="auth-title">Начните пользоваться прямо сейчас</div>
                <div
                    class="auth-description"
                    v-if="needCode"
                >Используйте для входа Газпром ID - единый аккаунт для вселенной цифровых сервисов:
                </div>
                <div
                    class="auth-description mb10"
                    v-else
                >
                    <template v-if="repeatCodeCounter <= repeatCodeCounterLimit">
                        Введите последние 3 цифры телефонного номера, с которого был совершён входящий звонок на номер <b
                        style="white-space: nowrap"
                    >{{ form.phone }}</b>
                    </template>
                    <template v-else>
                        Введите код из СМС, отправленный&nbsp;на&nbsp;номер
                        <br>
                        <b>{{ form.phone }}</b>
                    </template>
                </div>
                <!--                <img :src="images.phoneImg" alt="" class="phone-img desktop-only">-->
                <!--                <img src="@/assets/images/img-phone-mobile.png" alt="" class="phone-img mobile-only">-->
                <form
                    action="#"
                    method="post"
                    class="auth-form auth-form-send-sms"
                >
                    <template v-if="needCode">
                        <button
                            class="btn btn-gazprom-gid"
                            :class="{'loading': gidLoading}"
                            @click.prevent="gidAuth"
                        >
                            <svg viewBox="0 0 198 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g id="Logo_Gazprom_ID" clip-path="url(#clip0_15830_14922)">
                                    <path
                                        id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M43.7577 14.3054C42.0747 6.96409 37.8673 0.98658 37.2869 0C36.3874 1.3638 33.0214 6.6449 31.3094 12.5063C29.4523 19.0352 29.1621 24.8096 29.8295 30.5259C30.4679 36.2133 32.8763 42.0747 32.8763 42.0747C34.1531 45.1505 36.0682 48.4294 37.3159 50.0544C39.144 47.675 43.3515 40.5948 44.6572 31.3094C45.3827 26.1444 45.4117 21.6467 43.7577 14.3054ZM37.2869 48.2263C36.4744 46.6594 35.1977 43.7287 35.0526 39.144C35.0236 34.7334 36.7936 30.9322 37.3159 30.1487C37.7802 30.9322 39.2891 34.2401 39.4922 38.7378C39.6083 43.1483 38.1574 46.6304 37.2869 48.1973V48.2263ZM43.2644 26.7827C43.2064 29.5974 42.8582 32.5281 42.452 34.2981C42.597 31.2514 42.2488 26.9568 41.5234 23.5909C40.827 20.2249 38.8538 14.6246 37.2579 12.0421C35.807 14.5085 34.008 19.3544 33.0504 23.5618C32.0929 27.7693 32.0638 32.8473 32.0638 34.3852C31.8027 33.1085 31.1933 28.5238 31.3674 23.91C31.5125 20.1088 32.4121 16.1915 32.9053 14.3925C34.7624 8.38593 36.8807 4.55568 37.2579 3.94632C37.6641 4.55568 40.3047 9.28545 41.6685 14.2474C43.0323 19.2093 43.3225 23.9681 43.2644 26.7827Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_2" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M40.1306 51.7373H27.3631V60.2974C27.3631 60.2974 27.3631 60.2974 27.3921 60.2684C30.4099 57.2506 35.2557 57.2506 38.2735 60.2684C41.2913 63.2861 41.2913 68.132 38.2735 71.1497C38.2735 71.1497 38.2735 71.1498 38.2445 71.1788C38.2445 71.1788 38.2155 71.1788 38.1864 71.2078C35.1977 74.1965 31.2804 75.6764 27.3631 75.6764C23.4458 75.6764 19.4995 74.1675 16.4817 71.1788C11.2006 65.8977 10.5622 57.7149 14.5956 51.7373C15.1469 50.9249 15.7563 50.1704 16.4817 49.445C19.4995 46.4562 23.4168 44.9474 27.3631 44.9474V24.3743C12.2452 24.3743 0 36.6485 0 51.7373C0 66.8262 12.2452 79.1004 27.3631 79.1004C35.2557 79.1004 42.3359 75.7635 47.3268 70.4243V51.7083H40.1016L40.1306 51.7373Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_3" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M51.5054 51.8535H62.1256V55.3065H57.0476V79.6809H51.5054V51.8535Z" fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_4" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M68.103 69.2347L69.0606 55.3065H69.3798L70.3373 69.2347H68.103ZM61.9514 79.6809H67.4066L67.9289 72.2815H70.5405L71.0628 79.6809H76.518L73.6163 51.8535H64.8821L61.9804 79.6809H61.9514Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_5" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M83.8303 69.9311V75.1832C83.8303 75.9086 84.3816 76.1118 84.7008 76.1118C85.2231 76.1118 85.5713 75.6765 85.5713 75.1832V68.7124C85.5713 67.8419 85.3972 66.8843 83.337 66.8843H81.0737V63.8376H83.4241C85.02 63.8376 85.5713 63.4894 85.5713 61.7773V56.2351C85.5713 55.7708 85.2231 55.3356 84.7008 55.3356C84.3816 55.3356 83.8303 55.5387 83.8303 56.2351V60.7617H78.259V56.8154C78.259 54.8713 78.4912 51.8535 82.3795 51.8535H86.9932C90.8815 51.8535 91.1136 54.8713 91.1136 56.8154V61.255C91.1136 64.2728 88.9953 65.1143 87.3414 65.0273V65.3174C91.0846 65.2304 91.1136 68.1031 91.1136 69.0896V74.6899C91.1136 76.6341 90.8815 79.6518 86.9932 79.6518H82.3795C78.4912 79.6518 78.259 76.6341 78.259 74.6899V69.9311H83.8303Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_6" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M94.0442 51.8535H106.928V79.6809H101.385V55.3065H99.6155V79.6809H94.0442V51.8535Z" fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_7" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M115.749 67.1455V55.3065H116.474C117.084 55.3065 117.519 55.8289 117.519 56.6994V65.7817C117.519 66.6522 117.084 67.1745 116.474 67.1745H115.749V67.1455ZM110.178 79.6518H115.749V70.1923H118.912C122.8 70.1923 123.061 67.1745 123.061 65.2304V56.8154C123.061 54.8713 122.829 51.8535 118.912 51.8535H110.178V79.6809V79.6518Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_8" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M133.072 75.1831C133.072 75.6764 132.724 76.1117 132.202 76.1117C131.882 76.1117 131.331 75.9086 131.331 75.1831V56.235C131.331 55.5096 131.882 55.3355 132.202 55.3355C132.724 55.3355 133.072 55.7707 133.072 56.235V75.1831ZM125.76 74.7189C125.76 76.663 125.992 79.6808 129.909 79.6808H134.523C138.411 79.6808 138.643 76.663 138.643 74.7189V56.8444C138.643 54.9002 138.411 51.8824 134.523 51.8824H129.909C126.021 51.8824 125.76 54.9002 125.76 56.8444V74.7189Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_9" fill-rule="evenodd" clip-rule="evenodd"
                                        d="M155.967 51.8535H163.192V79.6809H157.65V62.2416H157.446L154.574 79.6809H150.134L147.261 62.2416H147.058V79.6809H141.516V51.8535H148.741L152.368 71.411L155.967 51.8535Z"
                                        fill="#0079C2"
                                    ></path>
                                    <path
                                        id="Vector_10"
                                        d="M183.649 54.7842H188.64C189.887 54.7842 190.903 55.0453 191.686 55.5386C192.499 56.0319 193.108 56.7573 193.544 57.6859C193.979 58.6144 194.298 59.7461 194.472 61.0519C194.646 62.3576 194.733 63.8085 194.733 65.4334C194.733 68.2191 194.559 70.4534 194.153 72.1944C193.776 73.9354 193.108 75.1832 192.209 75.9956C191.309 76.8081 190.12 77.1853 188.611 77.1853H183.62V54.7842H183.649ZM180.544 80H188.785C190.294 80 191.57 79.7098 192.644 79.2165C193.718 78.6942 194.588 77.9688 195.285 77.0693C195.981 76.1407 196.503 75.0961 196.91 73.9064C197.287 72.7167 197.577 71.44 197.722 70.1052C197.867 68.7704 197.954 67.4066 197.954 66.0138C197.954 64.621 197.896 63.2571 197.78 61.8933C197.664 60.5586 197.403 59.2818 197.055 58.0921C196.677 56.9024 196.155 55.8578 195.43 54.9583C194.704 54.0297 193.776 53.3333 192.586 52.811C191.425 52.2887 189.974 52.0276 188.204 52.0276H180.515V80.029L180.544 80ZM175.495 51.9985H172.39V80H175.495V51.9985Z"
                                        fill="#0079C2"
                                    ></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_15830_14922">
                                        <rect width="197.954" height="80" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        <div class="other-services-auth">Или войдите с помощью <a href @click.prevent="showCallForm = !showCallForm">обратного
                            звонка</a></div>
                        <template v-if="showCallForm">
                            <div class="phone-box">
                                <svg class="icon icon-phone">
                                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-phone"></use>
                                </svg>
                                <input
                                    type="text"
                                    name="phone"
                                    v-model="form.phone"
                                    ref="phone"
                                    inputmode="tel"
                                    v-maska="mask"
                                    @focus="onFocus"
                                    placeholder="Номер телефона"
                                >
                            </div>
                            <button
                                class="btn btn-dark"
                                :class="{'loading': loading}"
                                @click.prevent="getCode"
                            >
                                Заказать обратный звонок
                            </button>
                        </template>
                    </template>
                    <template v-else>
                        <div
                            class="mail-box"
                            style="border-bottom: none"
                        >
                            <!--                            <svg class="icon icon-mail">
                                                            <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-mail"></use>
                                                        </svg>-->

                            <div
                                class="digit-inputs"
                                :class="{'is-call': repeatCodeCounter <= repeatCodeCounterLimit}"
                            >
                                <input
                                    v-for="(digit, index) in digits"
                                    :key="index"
                                    v-model="digits[index]"
                                    type="text"
                                    ref="smscode"
                                    inputmode="tel"
                                    autocomplete="one-time-code"
                                    @input="moveToNextInput(index, $event.target.value)"
                                    @keydown.delete.prevent="moveToPrevInput(index)"
                                >
                            </div>
                        </div>
                        <div class="auth-form-repeat-code">
                            <template v-if="repeatCodeTimer<=0">
                                <a
                                    href
                                    @click.prevent="getCode"
                                >повторить звонок
                                    <template v-if="repeatCodeCounter>=repeatCodeCounterLimit"> SMS</template>
                                </a>
                                <a
                                    href
                                    @click.prevent="changePhone"
                                >изменить номер</a>
                            </template>
                            <a
                                href
                                v-else
                                @click.prevent
                            >Повторить звонок можно через {{ repeatCodeTimer }} сек.</a>
                        </div>

                        <div class="auth-form-agree" v-if="!userRegistered">
                            <label class="checkbox-container">
                                <span class="text">Согласен с условиями <a href="https://polyanaski.ru/privacy-policy/" target="_blank">политики
                                    конфиденциальности</a></span>
                                <input type="checkbox" name="agree" required v-model="agree">
                                <span class="checkmark"></span>
                            </label>
                        </div>

                        <button
                            class="btn btn-dark"
                            :class="{'loading': loading, 'disabled': !userRegistered && !agree}"
                            :disabled="!userRegistered && !agree"
                            @click.prevent="auth"
                        >Войти
                        </button>
                        <!--                        <div class="auth-form-footer">
                                                    Нажимая “Войти” я подтверждаю согласие <br>
                                                    с <a
                                                    href="https://polyanaski.ru/upload/medialibrary/rules/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%9E%D0%9E%D0%9E%20%D0%A1%D0%B2%D0%BE%D0%B4%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%88%D0%BD%D0%BB.pdf"
                                                    target="_blank">политикой обработки конфиденциальных данных</a>
                                                </div>-->
                    </template>
                </form>

                <!--                <a href="/" class="auth-logo"><img src="@/assets/images/logo.svg" class="header-logo" alt="GTC Gazprom"></a>-->
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
