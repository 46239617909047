import axios from "axios";
import {getHost} from "@/hooks/fnHelper";
import {useCookies} from "vue3-cookies";
import {svodApi} from "@/api/svodApi";
import deleteNonValidCookie from "@/hooks/deleteNonValidCookie";
import useServerRouters from "@/hooks/useServerRouters";
import {nextTick} from "vue";

export const initAppPlugin = async (app) => {
    const {cookies} = useCookies();

    deleteNonValidCookie();

    const getServerSid = async (tries = 0) => {
        const host = getHost();
        if (!cookies.isKey('server_sid')) {
            try {
                const {data: apiData} = await axios.get('https://api.polyanaski.ru/API/');
                if (!apiData?.session?.sid) {
                    throw new Error('No sid');
                }
                cookies.set('server_sid', apiData.session.sid, null, null, host);
                svodApi.defaults.headers.mrktsid = apiData.session.sid;
                // await delay(250);
            } catch (e) {
                if (tries < 5) {
                    return await getServerSid(++tries);
                }
            }
        }
    }

    const getServerRoutes = async (tries = 0) => {
        try {
            const {data: serverRouters} = await svodApi.get('/salegroups?t=all', {
                timeout: 10000
            });

            app.config.globalProperties.$serverRouters = serverRouters;
        } catch (e) {
            if (tries < 5) {
                return await getServerRoutes(++tries);
            }
            throw e;
        }
    }

    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

    await getServerSid();
    await getServerRoutes();
}