import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue3-cookies';
import Toaster from "@meforma/vue-toaster";
import {registerSW} from 'virtual:pwa-register';
import {initAppPlugin} from '@/plugins/initAppPlugin';
// import Sticky from 'vue3-sticky-directive';
import mitt from 'mitt';
import referrerPlugin from "@/plugins/referrerPlugin";

const emitter = mitt();

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (document.location.host === "market.polyanaski.ru") {
        try {
            let text = 'Name: ' + error.name + '\n' +
                'Message: ' + error.message + '\n' +
                error.stack + '\n';

            if (error.request) {
                text += '\n---REQUEST---\n';
                text += 'URL: ' + error.request.responseURL + '\n' +
                    'readyState: ' + error.request.readyState + '\n' +
                    'timeout: ' + error.request.timeout + '\n';
            }

            if (error.response) {
                text += '\n---RESPONSE---\n';
                text += 'Data: ' + (typeof error.response.data === 'object' ? JSON.stringify(error.response.data) : error.response.data) + '\n' +
                    'Status: ' + error.response.status + '\n';
            }

            let all_text = JSON.stringify(error);

            let data = new FormData();
            data.append('error', text);
            data.append('all_error', all_text);
            axios.post('/market_log.php', data);
        } catch (e) {
        }
        // axios.post()
    }
    // Do something with response error
    return Promise.reject(error);
});

registerSW({immediate: true});
let app;

app = createApp(App);
await initAppPlugin(app);
app.config.globalProperties.emitter = emitter;
app.use(referrerPlugin);
app.use(router);
app.use(VueAxios, axios);
app.use(Toaster, {
    position: "top-right",
    duration: 6000
});
app.use(VueCookies, {
    expireTimes: "365d",
});
app.mount('#app');

export default app;
